import { Card } from "react-bootstrap"
// Constants
import { colors } from "../../../constants"


const ExperienceCard = (props) => {
  return (
    <Card style={ styles.card } >
      <Card.Body style={ styles.body } >
        <div style={ styles.timeline } >
        <div style={ styles.timelineProgress } ></div>
        <div style={ styles.timelineItems } >
          <div style={ styles.timelineItem1 } >
            <img src="/icons/home.png" alt="work from home" style={styles.image} />
            <div style={ styles.date } >Feb 2007</div>
            <div style={ styles.date } >Freelance</div>
          </div>
          <div style={ styles.timelineItem2 } >
            <img src="/icons/accesssoftek.jpeg" alt="AccessSoftek" style={styles.image} />
            <div style={ styles.date } >Dec 2015</div>
            <div style={ styles.date } >AccessSoftek</div>
          </div>
          <div style={ styles.timelineItem3 } >
            <img src="/icons/wargaming.jpeg" alt="Wargaming" style={styles.image} />
            <div style={ styles.date } >Oct 2016</div>
            <div style={ styles.date } >Wargaming</div>
          </div>
          <div style={ styles.timelineItem4 } >
            <img src="/icons/luxoft.jpeg" alt="Luxoft" style={styles.image} />
            <div style={ styles.date } >Dec 2020</div>
            <div style={ styles.date } >Luxoft</div>
          </div>
          <div style={ styles.timelineItem5 } >
            <img src="/icons/intellect.jpeg" alt="IntellectEU" style={styles.image} />
            <div style={ styles.date } >Jun 2022</div>
            <div style={ styles.date } >IntellectEU</div>
          </div>
        </div>
      </div>
      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
    marginTop: 30,
    padding: 20,
    background: colors.main,
    borderColor: colors.main,
  },
  body: {
    backgroundColor: "eee"
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  image: {
    backgroundSize: "cover",
    height: 40,
    width: 40,
    marginTop: -50,
    borderRadius: 20
  },
  date: {
    fontSize: "10px",
    fontFamily: "monospace",
  },
  timeline: {
    marginTop: "30px",
    marginBottom: "20px",
    height: "2px",
    backgroundColor: "black"
  },
  timelineProgress: {
    width: "100%",
    height: "100%",
    backgroundColor: "black"
  },
  timelineItems: {
    marginLeft: "-10px",
    marginRight: "-10px",
    marginTop: "-12px",
    display: "flex",
    justifyContent: "space-between"
  },
  timelineItem1: {
    position: "absolute",
    left: "7%",
  },
  timelineItem2: {
    position: "absolute",
    left: "30%"
  },
  timelineItem3: {
    position: "absolute",
    left: "50%",
  },
  timelineItem4: {
    position: "absolute",
    left: "70%",
  },
  timelineItem5: {
    position: "absolute",
    left: "85%",
  },
};



export default ExperienceCard