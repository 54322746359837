import { Card } from "react-bootstrap"


const Language = ({ img, lang, descr }) => {
  return (
    <div>
      <span>
        <img src={img} alt={lang} style={styles.image} />
        <span style={styles.lang} >{lang}</span><span style={styles.descr} >{descr}</span>
      </span>
    </div>
  )
}


const LanguagesCard = (props) => {
  return (
    <Card style={ styles.card } >
      <Card.Title style={ styles.cardTtitle }>
      Languages
      </Card.Title>
      <Card.Body style={ styles.cardBody } >
        <Language img="/icons/flag-ua.png" lang="Ukrainian" descr=" - Native" />
        <Language img="/icons/flag-uk.png" lang="English" descr=" - Working proficiency" />
        <Language img="/icons/flag-pl.png" lang="Polish" descr=" - Working proficiency" />
        <Language img="/icons/flag-ru.png" lang="Russian" descr=" - Bilingual proficiency" />
      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
  },
  cardTtitle: {
    fontSize: 24,
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 0,
  },
  cardBody: {
    paddingTop: 0,
  },
  image: {
    backgroundSize: "cover",
    height: 15,
    width: 20,
    marginRight: 8,
  },
  lang: {
    fontSize: 12,
    fontFamily: "monospace",
    fontWeight: "bold",
  },
  descr: {
    fontSize: 10,
    fontFamily: "monospace",
  },
}


export default LanguagesCard