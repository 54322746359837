import { useEffect } from "react"


const CV = () => {
  useEffect(() => {
    document.title = "Anton Melentiev - CV"
  }, [])

  return (
    <div className='col-lg-12' style={styles.main}>
      <div style={styles.item}>
        <img src="/icons/pdf.png" style={styles.image} alt="pdf" />
        <a href="/files/Anton_Melentiev__CV.pdf" target="_blank" rel="noopener noreferrer canonical" style={styles.link} download>Download PDF</a>
      </div>
      <div style={styles.item}>
        <img src="/icons/word.png" style={styles.image} alt="word" />
        <a href="/files/Anton_Melentiev__CV.docx" target="_blank" rel="noopener noreferrer canonical" style={styles.link} download>Download WORD</a>
      </div>
    </div>
  )
}


const styles = {
main: {
  height: "calc(100vh - 164px)",
  width: "100vw",
  display: "table-cell",
  textAlign: "center",
  verticalAlign: "middle",
},
item: {
  paddingBottom: 40,
},
image: {
  backgroundSize: "cover",
  height: 64,
  width: 64,
  marginRight: 10,
  
},
link: {
  fontSize: 24,
  fontFamily: "monospace",
  fontWeight: "bold",
},
}


export default CV