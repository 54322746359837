import { useEffect } from "react"


const Contact = ({ img, alt, text, link }) => {
  return (
    <div style={styles.contact}>
      <span>
        <img src={img} alt={alt} style={styles.image} />
        <a href={link} target="_blank" rel="noopener noreferrer" style={styles.link} >{text}</a>
      </span>
    </div>
  )
}

const Contacts = (props) => {
  useEffect(() => {
    document.title = "Anton Melentiev - Contacts"
  }, [])

  return (
    <div className="col-lg-12" style={styles.main}>
        <Contact img="/icons/linkedin.png" alt="Linkedin" text="Linkedin" link="https://www.linkedin.com/in/antonmelentiev" />
        <Contact img="/icons/email.png" alt="info@AntonMelentiev.com" text="info@AntonMelentiev.com" link="mailto:info@AntonMelentiev.com" />
        <Contact img="/icons/mobile.png" alt="+48 500 358 654" text="+48 500 358 654" link="tel:+48500358654" />
        <Contact img="/icons/signal.png" alt="Signal" text="Signal" link="https://signal.me/#eu/bVdGILLFxevL8hB4N8HiCwmw4NW2zgTydzIqTEAJ-EwCW-VddFRDQ49RJe9kzKMz" />
        <Contact img="/icons/telegram.png" alt="Telegram" text="Telegram" link="https://t.me/AntonMelentiev" />
        <Contact img="/icons/skype.png" alt="Skype" text="Skype" link="https://join.skype.com/invite/dW47Mg1I8EDE" />
    </div>
  )
}


const styles = {
  main: {
    height: "calc(100vh - 164px)",
    width: "100vw",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
  },
  contact: {
    marginBottom: 15,
  },
  image: {
    backgroundSize: "cover",
    height: 64,
    width: 64,
    marginRight: 10,
    
  },
  link: {
    fontSize: 24,
    fontFamily: "monospace",
    fontWeight: "bold",
  },
}


export default Contacts