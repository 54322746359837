import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ReactGA from "react-ga4"

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Initialize Google Analytics
if (process.env.NODE_ENV === 'development') {
  ReactGA.initialize("G-7B4YJ776B4!")
} else {
  ReactGA.initialize("G-7B4YJ776B4")
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
