import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"


const Home = () => {
  useEffect(() => {
    const headerHeight = document.getElementById("header").clientHeight
    document.getElementById("HomeContainer").style.minHeight = `calc(100vh - ${headerHeight}px`
    document.getElementById("HomeRow1").style.minHeight = `calc(100vh - ${headerHeight}px`
    document.getElementById("HomeRow2").style.minHeight = `calc(100vh - ${headerHeight}px`
    document.getElementById("leftCol").style.minHeight = `calc(100vh - ${headerHeight}px`
    document.title = "Anton Melentiev"
  }, [])
  
  return (
    <Container id="HomeContainer">
      <div className="d-none d-lg-block">
        <Row id="HomeRow1">
          <div className="col-lg-6">
            <Col id="leftCol" style={styles.bgImg}/>
          </div>
          <div className="col-lg-6" style={styles.text}>
            <Col>
              Hello! <br/><br/>
              My name is <strong>Anton Melentiev</strong><br/><br/>
              ISTQB Full Advanced certified<br/>
              QA Manager | QA Lead<br/>
              Test Automation Expert (SDET)<br/><br/>
              with more than 15 years of experience!
            </Col>
          </div>    
        </Row>
      </div>

      <div className="d-lg-none">
        <Row id="HomeRow2">
          <Col style={styles.text}>
            <div style={styles.bgImgMobile} />
            <div>
              Hello! <br/><br/>
              My name is <br/>
              <strong>Anton Melentiev</strong><br/><br/>
              ISTQB Full Advanced certified<br/>
              QA Manager | QA Lead<br/>
              Test Automation Expert (SDET)<br/><br/>
              with more than 15 years of experience!
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  )
}


const styles = {
  bgImg: {
    // height: "calc(100% + 40px)",
    backgroundImage: "url('/photos/photo.webp')",
    backgroundPosition: "center bottom",
    // backgroundPositionX: "left",
    // backgroundPositionY: "bottom",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    opacity: .9,
  },
  bgImgMobile: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "100%",
    backgroundImage: "url('/photos/photo.webp')",
    backgroundPosition: "center bottom",
    backgroundSize: "115%",
    backgroundRepeat: "no-repeat",
    opacity: .25,
  },
  text: {
    fontFamily: "monospace",
    textAlign: "center",
    alignItems: "center",
    alignSelf: "center",
    color: "#000",
    padding: "75px 50px 75px !important",
    fontSize: "max(1.95vw, 26px)",
    fontWeight: 600,
  },
}


  export default Home