import { Card, ListGroup } from "react-bootstrap"


const Item = ({ style, text, imgSource, descr = "" }) => {
  return (
    <ListGroup.Item style={styles.listGroupItem}>
      <span style={style} />
      <img src={imgSource} alt="img" style={styles.image} />
      <div>
        <div>
        {text}
        </div>
        <div style={styles.descrption} >
          {descr}
        </div>
      </div>
  </ListGroup.Item>
  )
}


const TechnologiesCard = (props) => {

  return (
    <Card style={ styles.card } >
      <Card.Title style={ styles.cardTtitle }>
        Techonologies
        <div style={ styles.agenda } >
          <div style={ styles.agendaItem } >
            <span style={styles.expert} /><span>Can solve complex problems and/or teach a team.</span>
          </div>
          <div style={ styles.agendaItem } >
            <span style={styles.medium} /><span>Have practical experience.</span>
          </div>
          <div style={ styles.agendaItem } >
            <span style={styles.beginer} /><span>Have teoretical knowledge.</span>
        </div>
        </div>
      </Card.Title>
      <Card.Body style={ styles.cardBody } >

        <ListGroup style={ styles.listGroup } >
          <div>Main</div>
          <Item style={styles.expert} text="Python" imgSource="/icons/python.png" descr="Programming language" />
          <Item style={styles.expert} text="Docker" imgSource="/icons/docker.png" descr="Virtualization platform" />
          <Item style={styles.expert} text="Linux" imgSource="/icons/linux.png" descr="Operation system" />
          <Item style={styles.expert} text="PyTest" imgSource="/icons/pytest.png" descr="Python testing framework" />
          <Item style={styles.expert} text="REST API" imgSource="/icons/rest-api.png" />
          <Item style={styles.expert} text="GIT" imgSource="/icons/git.png" />
          <Item style={styles.expert} text="Selenium" imgSource="/icons/selenium.png" />
          
          <Item style={styles.medium} text="JavaScrip" imgSource="/icons/js.png" descr="Programming language" />
          <Item style={styles.medium} text="SQL" imgSource="/icons/sql.png" />
          <Item style={styles.medium} text="Mongo" imgSource="/icons/mongo.png" descr="No SQL DB" />
          <Item style={styles.medium} text="Jenkins" imgSource="/icons/jenkins.png" descr="CI/CD tool" />
          <Item style={styles.medium} text="Locust.io" imgSource="/icons/locust.png" descr="Performance testing framework for Python" />
          <Item style={styles.medium} text="Postman" imgSource="/icons/postman.png" />

          <Item style={styles.beginer} text="Java" imgSource="/icons/java.png" descr="Programming language" />

          <div style={ styles.listGroup } >Other</div>
          <Item style={styles.expert} text="Jira" imgSource="/icons/jira.png" descr="Bug tracking system" />
          <Item style={styles.expert} text="Confluence" imgSource="/icons/confluence.png" />
          <Item style={styles.expert} text="Automated UI testng" imgSource="/icons/ui.png" descr="Using Python, PyTest, and Selenium" />
          <Item style={styles.expert} text="Flask" imgSource="/icons/flask.png" descr="Python framework" />
          <Item style={styles.expert} text="PyCharm" imgSource="/icons/pycharm.png" descr="IDE for Python" />
          <Item style={styles.expert} text="TestRail" imgSource="/icons/testrail.png" descr="Bug tracking system" />
          <Item style={styles.expert} text="Allure Framework" imgSource="/icons/allure.png" descr="Test report tool" />
          <Item style={styles.expert} text="Xray" imgSource="/icons/xray.png" />

          <Item style={styles.medium} text="Redis" imgSource="/icons/redis.png" />
          <Item style={styles.medium} text="GNU Bash" imgSource="/icons/bash.png" />
          <Item style={styles.medium} text="Grafana" imgSource="/icons/grafana.png" descr="Monitoring tool" />
          <Item style={styles.medium} text="Django" imgSource="/icons/django.png" descr="Python framework" />
          <Item style={styles.medium} text="Xray" imgSource="/icons/xray.png" />
          <Item style={styles.medium} text="HTML" imgSource="/icons/html.png" />
          <Item style={styles.medium} text="CSS" imgSource="/icons/css.png" />
          <Item style={styles.medium} text="XPath" imgSource="/icons/xpath.png" />
          <Item style={styles.medium} text="macOS" imgSource="/icons/macos.png" />
          <Item style={styles.medium} text="Visual Studio Code" imgSource="/icons/visual_studio.png" />
          <Item style={styles.medium} text="Lucid chart" imgSource="/icons/lucid.png" descr="Diagramming application" />

          <Item style={styles.beginer} text="Android Studio" imgSource="/icons/androidstudio.png" descr="Plugin for Jira for tests traceability" />
          <Item style={styles.beginer} text="Cypress.io" imgSource="/icons/cypress.png" />
          <Item style={styles.beginer} text="Appache JMeter" imgSource="/icons/jmeter.png"  descr="Performance testing framework for Java" />
          <Item style={styles.beginer} text="K6" imgSource="/icons/k6.png"  descr="Open-source performance testing tool" />
        </ListGroup>
      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
    maxHeight: 955,
  },
  cardTtitle: {
    fontSize: 24,
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 0,
  },
  agenda: {
    paddingTop: 5,
  },
  agendaItem: {
    display: "flex",
    height: 30,
    alignItems: "center",
    fontSize: "12px",
    fontFamily: "monospace",
    fontWeight: "normal",
  },
  cardBody: {
    overflow: "auto",
    marginTop: 0,
    marginBottom: 20,
  },
  image: {
    width: "35px",
    height: "35px",
    marginRight: 10,
  },
  listGroup: {
    marginTop: 20,
  },
  listGroupItem: {
    display: "flex",
    alignItems: "center",
    border: "none",
    paddingTop: 0,
    paddingBottom: 5,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: "bold",
  },
  expert: {
    width: 10,
    height: 15,
    flex: "0 0 10px",
    borderRadius: 5,
    background: "#000080",
    marginRight: 10,
  },
  medium: {
    width: 10,
    height: 15,
    flex: "0 0 10px",
    borderRadius: 5,
    background: "green",
    marginRight: 10,
  },
  beginer: {
    width: 10,
    height: 15,
    flex: "0 0 10px",
    borderRadius: 5,
    background: "orange",
    marginRight: 10,
  },
  descrption: {
    fontSize: 10,
    fontFamily: "monospace",
    fontWeight: "normal",
    padding: 0,
    margin: 0,
  },
}


export default TechnologiesCard