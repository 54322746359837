import { Card } from "react-bootstrap"


const AboutMeCard = () => {
  return (
    <Card
      style={ styles.card }
    >
      <Card.Body style={ styles.body }>
        <Card.Title style={ styles.title }>
          About me
        </Card.Title>
          <div style={ styles.text } >
            <p><u>Experience and Skills</u><br/>As a highly skilled and experienced <strong>QA Manager</strong> and <strong>QA Lead</strong> with proficiency in <strong>manual</strong> and <strong>automated testing</strong>, I have extensive experience in managing and leading teams of QA engineers to ensure the delivery of high-quality software products. My ability to coordinate and prioritize testing activities, plan and execute comprehensive testing strategies, and track and report on quality metrics, along with my skills in identifying and mitigating risks and effectively communicating with stakeholders, make me a valuable asset to any software development team.</p>
            <p><u>Mentoring and coaching</u><br/>My passion for <strong>mentoring</strong> and <strong>coaching</strong> team members, promoting a culture of quality, and continuously improving processes and tools, is evident in my experience in team building and interviewing candidates for QA roles.</p>
            <p><u>Team Building</u><br/>I believe in building a strong team with a diverse set of skills and backgrounds to deliver high-quality software products. When interviewing candidates, I focus not only on their <em>technical skills</em> but also on their <em>attitude</em>, <em>communication skills</em >, and ability to work collaboratively with others.</p>
            <p><u>Ideal Candidate for QA Lead positions</u><br/>My background in <strong>software engineering</strong> and testing, including proficiency in various testing methodologies and tools, makes me an ideal candidate for <strong>QA Lead</strong> and <strong>QA Automation Lead</strong> positions. I have experience working with <strong>Agile methodologies</strong>, including Scrum and Kanban, which demonstrates my adaptability and ability to work well in a fast-paced, collaborative environment. My strong <strong>analytical</strong> and <strong>problem-solving skills</strong>, along with my commitment to continuous learning and growth, make me a valuable asset to any software development team.</p>
            <p><u>Conclusion</u><br/>Overall, my experience and skills enable me to lead teams of QA engineers to deliver high-quality software products while fostering a culture of quality and continuous improvement. I believe in promoting collaboration and trust among team members to ensure the success of any software development project.</p>
        </div>
      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
  },
  body: {
    paddingRight: 0,
  },
  title: {
    paddingTop: 10,
    paddingLeft: 10,
    fontSize: 24,
    fontWeight: "bold",
  },
  text: {
    height: 490,
    paddingLeft: 10,
    paddingRight: 20,
    overflow: "auto",
    fontSize: "12px",
    fontFamily: "monospace",
    textAlign: "justify",
  }
}


export default AboutMeCard