import { BrowserRouter } from "react-router-dom"
// Components
import Header from "./components/Header"
import Main from "./components/Main"
// Constants
import { colors } from "./constants"


function App() {
  return (
    <BrowserRouter>
      <div className="App" style={styles.appStyle}>
        <Header />
        <Main />
      </div>
    </BrowserRouter>
  )
}


const styles = {
  appStyle: {
    background: colors.main,
  },
}


export default App
