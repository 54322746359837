import { useEffect } from "react"
import { Container } from "react-bootstrap"
// local
import AboutMeCard from "./cards/AboutMe"
import CertificationsCard from "./cards/Certifications"
import ExperienceCard from "./cards/Experience"
import FocusCard from "./cards/Focus"
import LanguagesCard from "./cards/Languages"
import SoftCard from "./cards/Soft"
import TechonologiesCard from "./cards/Technologies"
// constants


const About = () => {
  useEffect(() => {
    document.title = "Anton Melentiev - About"
  }, [])
  
  return (
    <Container>
      <div className="col-12">
        <ExperienceCard/>
      </div>

      <div className="d-lg-flex d-none justify-content-between">
        <div className="col-lg-3 col-12">
          <FocusCard/>
          <TechonologiesCard/>
        </div>
        <div  className="col-lg-6 col-12">
          <AboutMeCard/>
          <SoftCard/>
        </div>
        <div className="col-lg-3 col-12">
          <LanguagesCard/>
          <CertificationsCard/>
        </div>
      </div>

      <div className="d-lg-none justify-content-between">
        <AboutMeCard/>
        <FocusCard/>
        <SoftCard/>
        <TechonologiesCard/>
        <LanguagesCard/>
        <CertificationsCard/>
      </div>
    </Container>
  )
}


export default About