export const localPaths = {
    home: "/",
    about: "/about",
    projects: "/projects",
    cv: "/cv",
    contacts: "/contacts",
    blog: "/blog",
    blog1: "/blog/1",
  }

  export const colors = {
    main: "#eee"
  }