import { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import ReactGA from "react-ga4"
// components
import Home from "./Home"
import About from "./About/About"
import Projects from "./Projects"
import CV from "./CV"
import Contacts from "./Contacts"
import Blog from "./Blog/Blog"
import Blog1 from "./Blog/Blog1"
import NotFound from "./NotFound"
// constants
import { localPaths } from "../constants"


function Main() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search })
  }, [location])

  useEffect(() => {
    const headerHeight = document.getElementById("header").clientHeight
    document.getElementById("main").style.paddingTop = `${headerHeight}px`
  }, [])

  return (
    <main id={"main"} style={ styles.main }>
      <Routes>
        <Route path={localPaths.home} element={ <Home/> } />
        <Route path={localPaths.about} element={ <About/> } />
        <Route path={localPaths.projects} element={ <Projects/> } />
        <Route path={localPaths.contacts} element={ <Contacts/> } />
        <Route path={localPaths.cv} element={ <CV/> } />
        {/*<Route path={localPaths.blog} element={ <Blog/> } />*/}
        {/*<Route path={localPaths.blog1} element={ <Blog1/> } />*/}
        <Route path="*" element={ <NotFound/> } />
      </Routes>
    </main>
  )
}


const styles = {
  main: {
    width: "98%",
    minHeight: "100vh",
    margin: "auto",
  }
}


export default Main
