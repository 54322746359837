import { useState, useEffect } from "react"


const NotFound = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, []);

  const imgStyle = {
    opacity: isVisible ? 1 : 0,
    transition: "opacity 3s"
  }

  return (
    <div className='col-lg-12' style={styles.main}>
    <img style={imgStyle} src="icons/404.png" alt="Not found =)" />
    </div>
  )
}


const styles = {
  main: {
    height: "calc(100vh - 164px)",
    width: "100vw",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
  },
}


export default NotFound
