import { useEffect } from "react"
import Card from "react-bootstrap/Card"
import Carousel from "react-bootstrap/Carousel"
// constants
import { colors } from "../constants"


const Project = ({ img, link, title, texts }) => {
  useEffect(() => {
    document.title = "Anton Melentiev - Projects"
  }, [])

  return (
    <Card style={styles.card}>
      <Card.Img variant="top" src={img} alt="Project image" style={styles.img} />
      <Card.Body style={styles.body}>
        {link === null ? (
          <Card.Title style={styles.title}>{title}</Card.Title>
        ) : (
          <Card.Title style={styles.title}>
            <a href={link} target="_blank" rel="noopener noreferrer" style={styles.link}>
              {title}
            </a>
          </Card.Title>
        )}
        {texts.map((text, idx) => (
          <div key={idx} style={styles.text}>{text}</div>
        ))}
      </Card.Body>
    </Card>
  )
}


const Projects = () => {
  return (
    <div className='col-lg-6 col-10' style={styles.main}>
      <Carousel
        variant="dark"
        style={styles.carousel}
        nextIcon={<img src="/icons/next.png" alt="Previous" style={styles.next} />}
        prevIcon={<img src="/icons/prev.png" alt="Previous"  style={styles.prev} />}
      >
        <Carousel.Item as={Card} style={styles.noBorder}>
          <Project 
            img="/img/blockchain.png" 
            link="https://intellecteu.com/"
            title="Blockchain and SWIFT projects" 
            texts={[
              "As a QA Chapter Lead for IntellectEU, my primary focus is on managing the team and leading the testing process for various projects, including those involving SWIFT and different blockchain technologies.",
              "In my role, I am responsible for ensuring that the team is performing at the highest level, adhering to best practices, and delivering high-quality software. I work closely with the development team to ensure that the testing process is well-integrated into the development cycle and that issues are addressed promptly.",
              "One of my key achievements has been to implement and refine a set of testing processes and procedures that have improved the overall quality of our software. This has resulted in better collaboration between development and testing teams, faster turnaround times for issue resolution, and increased confidence in the quality of our products.",
              "As a leader, I strive to foster an environment that is supportive, inclusive, and focused on continuous improvement. I regularly review and assess the skills and capabilities of the team, identifying areas for improvement and working to provide training and support where necessary.",
              "Overall, my experience as a QA Chapter Lead has allowed me to develop my leadership skills, work on exciting and challenging projects, and help deliver high-quality software to our clients. I am still working hard to improve our processes, and I am committed to ensuring that our team continues to perform at the highest level.",
            ]} 
          />
        </Carousel.Item>

        <Carousel.Item as={Card} style={styles.noBorder}>
          <Project 
            img="/img/directv.png" 
            link="https://www.directv.com/" 
            title="DirecTV" 
            texts={[
              "As a QA Automation Engineer, I had the opportunity to work on a challenging project for DirecTV, where I wrote an automation framework from scratch to test the embedded software of satellite boxes. The framework was written in Python using Django, MongoDB, SQL DB, Redis, and Redis Queue. My main responsibility was to develop a Live video stream analysis framework that could constantly monitor and analyze the RTSP streams for any issues such as black or blue screens or frozen images.",
              "To achieve this, I implemented a video buffer that could save a part of the stream video starting from some time before the problem occurred. Additionally, I created a system for auto-reconnection to the streams and auto-recovery after an error, which involved interacting with a redrat device via the redrat-hub API. The framework was also designed to create new Django workers for each stream, which made monitoring the streams more efficient.",
              "My achievements in this project included developing a reliable and robust framework that provided accurate results and helped the team identify and address issues quickly. I also successfully integrated the framework with the redrat device, which greatly improved the automation process. Overall, this project was a significant accomplishment, and I am proud to have been part of the team that made it happen.",
            ]} 
          />
        </Carousel.Item>

        <Carousel.Item as={Card} style={styles.noBorder}>
          <Project 
            img="/img/wot.jpg" 
            link="https://worldoftanks.eu/" 
            title="World of Tanks" 
            texts={[
              "As a QA responsible for releases of several features in the game \"World of Tanks,\" I had the opportunity to work on a complex and challenging project. I worked closely with the development team to ensure that all features were thoroughly tested and met the high standards of the game.",
              "Additionally, I also switched to the web automation team, where I was responsible for designing and implementing automated tests for the game's web application. This involved creating test scripts, running automated tests, and analyzing results to identify any issues that needed to be addressed.",
              "The complexity of this project was significant, given the nature of the game and the number of features involved. It required a deep understanding of the game mechanics, as well as the ability to work closely with developers, designers, and other stakeholders to ensure that the game met the high standards expected by its player base.",
              "My achievements on this project included successfully leading the QA process for several features, which helped to identify and address critical issues before the game was released. Additionally, my work on the web automation team helped to improve the testing process and increase efficiency. Overall, this project was a significant accomplishment, and I am proud to have been part of the team that made it happen.",
            ]} 
          />
        </Carousel.Item>

        <Carousel.Item as={Card} style={styles.noBorder}>
          <Project 
            img="/img/mfm.webp" 
            link="https://www.accesssoftek.com"
            title="Digital Banking Platform" 
            texts={[
              "I had the opportunity to work on a project testing the mobile clients for Access Softek's digital banking platform on iOS and Android. My primary role was to ensure that the mobile clients were functioning correctly and met the high standards of the platform.",
              "This project was a crucial part of the development process for the digital banking platform, as the mobile clients were a critical component of the overall user experience. My achievements in this project included identifying and addressing critical issues with the mobile clients, ensuring that the platform was ready for release to the public.",
              "Although I work on this project for relatively short period, it was an important experience that allowed me to improve my testing skills and gain valuable experience in mobile client testing.",
            ]} 
          />
        </Carousel.Item>

        <Carousel.Item as={Card} style={styles.noBorder}>
          <Project 
            img="/img/other.png" 
            link={null} 
            title="Small projects" 
            texts={[
              "As a freelance QA Engineer, I worked on more than 15 mobile and desctop projects, each with its unique set of challenges and requirements. My role on these projects was to ensure that the software was thoroughly tested and met the high standards expected by the clients.",
              "On each of these projects, my achievements included successfully identifying critical issues and ensuring that they were addressed promptly. Additionally, I was able to improve the overall quality of the software through thorough testing and close collaboration with the development team.",
              "Overall, my experience as a freelance QA Engineer allowed me to work on a variety of projects and gain valuable experience in different industries and technologies. It also helped me develop my skills in testing, automation, and project management, and I am grateful for the opportunity to have worked on these projects.",
            ]} 
          />
        </Carousel.Item>

      </Carousel>
    </div>
  )
}


const styles = {
  noBorder: {
    border: 0,
  },
  main: {
    display: "block",
    margin: "auto",
    paddingTop: 40,
    textAlign: "center",
  },
  img: {
    background: colors.main,
    maxHeight: "20vh",
    objectFit: "contain",
  },
  card: {
    height: "calc(100vh - 130px)",
    border: 0,
    overflow: "auto",
    paddingBottom: 20,
  },
  body: {
    background: colors.main,
    border: 0,
  },
  text: {
    fontFamily: "monospace",
    fontSize: 14,
    paddingTop: 20,
  },
  next: {
    width: "10vw",
    height: "10vw",
    marginLeft: "15vw",
  },
  prev: {
    width: "10vw",
    height: "10vw",
    marginRight: "15vw",
  },
  }

export default Projects