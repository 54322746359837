import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
// constants
import { colors, localPaths } from "../constants"


const Header = () => {

  return (
    <header id={"header"} style={styles.headerStyle}>
      <Navbar collapseOnSelect style={styles.navbar} expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarSupportedContent" style={styles.toggleBtn} />
          <Navbar.Collapse id="navbarScroll" style={styles.navbarCollapseCustom}>
            <Nav
              className="me-auto my-2 my-lg-0"
              style={styles.navbar}
              navbarScroll
            >
              <Nav.Link as={Link} style={styles.navLink} to={localPaths.home} eventKey="1" >HOME</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} to={localPaths.about} eventKey="2" >ABOUT</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} to={localPaths.projects} eventKey="3" >PROJECTS</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} to={localPaths.cv} eventKey="4" >CV</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} to={localPaths.contacts} eventKey="5" >CONTACTS</Nav.Link>
              {/*<Nav.Link as={Link} style={styles.navLink} to={localPaths.blog} eventKey="6" >BLOG</Nav.Link>*/}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}


const styles = {
  headerStyle: {
    position: "absolute",
    width: "100%",
    height: "84px",
    padding: 0,
    paddingTop: "20px !important",
  },
  navbar: {
    paddingTop: "20px",
    textAlign: "center",
    margin: "auto",
    zIndex: 100,
  },
  toggleBtn: {
    marginBottom: 10,
  },
  navbarCollapseCustom: {
    background: colors.main,
    borderRadius: "15px",
    opacity: .95,
  },
  navLink: {
    textDecoration: "underline",
    fontSize: "20px",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  }
}


export default Header
