import { Card } from "react-bootstrap"
import WordCloud from "react-wordcloud"


const words = [
  { text: "Leadership", value: 20 },
  { text: "Focus on Quality", value: 15 },
  { text: "Adaptability", value: 13 },
  { text: "Proactivity", value: 12 },
  { text: "Problem solving", value: 12 },
  { text: "Process management", value: 12 },
  { text: "Fast learning", value: 10 },
  { text: "Team work", value: 10 },
  { text: "Collaboration", value: 10 },
  { text: "Communication", value: 10 },
  { text: "Flexibility", value: 10 },
  { text: "Critical thinking", value: 8 },
  { text: "Team management", value: 8 },
  { text: "Active listening", value: 7 },
  { text: "Open mindness", value: 7 },
  { text: "Creativity", value: 7 },
  { text: "Attention to detail", value: 5 },
  { text: "Stress tolerance", value: 5 },
  { text: "Empathy", value: 5 },
]

const options = {
  colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd'],
  fontSizes: [12, 36],
  fontFamily: "monospace",
  fontWeight: "bold",
  rotations: 3,
  rotationAngles: [-90, -45, 0, 45, 90],
  enableTooltip: false,
  willReadFrequently: true,
}


const SoftCard = (props) => {
  return (
    <Card style={ styles.card } >
      <Card.Title style={ styles.cardTtitle }>
        Soft skills
      </Card.Title>
      <Card.Body style={ styles.cardBody } >
        <div style={ styles.cloud } >
          <WordCloud words={words} options={options} /> 
        </div>
      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
    height: 805,
  },
  cardTtitle: {
    fontSize: 24,
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 0,
  },
  cardBody: {
    display: "flex",
    padding: 0,
  },
  cloud: {
    width: "100%",
    height: "100%",
  },
}


export default SoftCard