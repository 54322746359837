import { Card } from "react-bootstrap"
import { useState, useEffect } from "react"

const CircleText = ({ text, size=50, fontSize=16, backgroundColor="black", color="white", startX=0, startY=0 }) => {
  const [currentPositionX, setCurrentPositionX] = useState(startX)
  const [currentPositionY, setCurrentPositionY] = useState(startY)

  useEffect(() => {
    setCurrentPositionX(0)
    setCurrentPositionY(0)
  }, [])

  const style = {
    width: size,
    height: size,
    margin: 0,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor,
    color,
    fontSize,
    fontWeight: "bold",
    textAlign: "center",
    transform: `translateX(${currentPositionX}px) translateY(${currentPositionY}px)`,
    transition: "transform 1s",
  }

  return (
    <span style={style}>
      {text}
    </span>
  )
}


const FocusCard = (props) => {
  return (
    <Card style={styles.card}>
      <Card.Title style={styles.cardTtitle}>
        Focus
      </Card.Title>
      <Card.Body style={styles.cardBody}>
        <div style={styles.gridStyles}>
          <CircleText key={1} text="Team management" size={80} fontSize={12} startX={100} startY={200} />
          <CircleText key={2} text="Process management" size={75} fontSize={10} startX={-100} startY={200} />
          <CircleText key={3} text="Leadership" size={120} fontSize={16} startX={0} startY={100} />
          <CircleText key={4} text="Building process" size={55} fontSize={8} startX={-100} startY={100} />
          <CircleText key={5} text="Test automation" size={65} fontSize={10} startX={100} startY={-100} />
          <CircleText key={6} text="Blockchain" size={80} fontSize={13} startX={-100} startY={-100} />
          
        </div>
      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
  },
  cardTtitle: {
    fontSize: 24,
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 0,
  },
  cardBody: {
    display: "block",
  },
  gridStyles: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(80px, 1fr))",
    gap: "10px",
    padding: "15px",
    height: "100%",
  },
}

export default FocusCard
