import { Card } from "react-bootstrap"

const Certificate = ({ img, name, descr, date, link }) => {
  return (
    <div style={ styles.cert } >
      <span style={{display: "block"}}>
        <img src={img} alt="img" style={styles.image}/>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src="/icons/external_link.png" alt="img" style={styles.extLinkImg}/>
        </a>

      </span>
      <div style={styles.name}>{name}</div>
      <div style={styles.descr}>{descr}</div>
      <div style={styles.line}/>
      <div style={styles.date}>{date}</div>
    </div>
  )
}

const CertificationsCard = (props) => {
  return (
    <Card style={ styles.card } >
      <Card.Title style={ styles.cardTtitle }>
      Certifications
      </Card.Title>
      <Card.Body style={ styles.cardBody } >
        <Certificate
            img="/icons/isqi_logo-2.jpg"
            name="CTAL-FULL - Certified Tester Advanced level - Full"
            descr="ISTQB - Internetional Software Testing Qualification Board, License 24-CTAL-247584-12"
            date="Jul 2024 - Doesn't expire"
            link="https://isqi.skillsclub.com/34419a38-2b5f-4505-b317-b0b802ab2cdd"
        />
        <Certificate
            img="/icons/istqb.png"
            name="CTAL-TM - Certified Tester Advanced level - Test Manager"
            descr="ISTQB - Internetional Software Testing Qualification Board, License 24-CTAL-TM-241264-12"
            date="Feb 2024 - Doesn't expire"
            link="http://scr.istqb.org/?name=Anton+Melentiev&number=24-CTAL-TM-241264-12"
        />
        <Certificate
          img="/icons/istqb.png" 
          name="CTAL-TTA - Certified Tester Advanced level - Technical Test Analyst"
          descr="ISTQB - Internetional Software Testing Qualification Board, License 11702-3" 
          date="May 2019 - Doesn't expire"
          link="http://scr.istqb.org/?name=Anton+Melentiev&number=11702-3"
        />
        <Certificate
            img="/icons/istqb.png"
            name="CTAL-TA - Certified Tester Advanced level - Test Analyst"
            descr="ISTQB - Internetional Software Testing Qualification Board, License 24-CTAL-TA-246921-12"
            date="May 2019 - Doesn't expire"
            link="http://scr.istqb.org/?name=Anton+Melentiev&number=24-CTAL-TA-246921-12"
        />
        <Certificate
            img="/icons/istqb.png"
            name="CTFL - Certified Tester Foundation Level"
            descr="ISTQB - Internetional Software Testing Qualification Board, License 50646"
            date="Sep 2017 - Doesn't expire"
            link="http://scr.istqb.org/?name=Anton+Melentiev&number=50646"
        />
        <Certificate 
          img="/icons/coursera.png" 
          name="Python for Everybody"
          descr="Univercity of Michigan, License 7M2WF5Q4PZ3N" 
          date="Apr 2016 - Doesn't expire"
          link="https://www.coursera.org/account/accomplishments/specialization/7M2WF5Q4PZ3N"
        />

        <div style={styles.credentials}>
          <p style={styles.name}>Check all ISTQB certificates here</p>

          <a href="http://scr.istqb.org/?name=Anton+Melentiev" target="_blank" rel="noopener noreferrer">
            <img src="/icons/istqb.png" alt="ISTQB img" style={styles.extLinkImgCred}/>
          </a>

          <a href="https://www.gasq.org/en/registration/expert/6aa7e81b-f85d-4625-b37c-f37e7b894b73.html" target="_blank" rel="noopener noreferrer">
            <img src="https://www.gasq.org/logo__1_1.png" alt="GASQ img" style={styles.extLinkImgCred}/>
          </a>

          <a href="https://skillshub.isqi.org/profile/antonmelentiev/wallet" target="_blank" rel="noopener noreferrer">
            <img src="/icons/isqi_logo.jpg" alt="ISQI img" style={styles.extLinkImgCred}/>
          </a>
        </div>

      </Card.Body>
    </Card>
  )
}


const styles = {
  card: {
    margin: 5,
  },
  cardTtitle: {
    fontSize: 24,
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 0,
  },
  cardBody: {
    paddingTop: 0,
  },
  cert: {
    borderRadius: 10,
    border: "1px solid grey",
    padding: 8,
    marginBottom: 10,
  },
  image: {
    backgroundSize: "cover",
    height: 24,
    width: 48,
  },
  extLinkImg: {
    backgroundSize: "cover",
    height: 24,
    width: 24,
    float: "right",
  },
  credentials: {
    textAlign: "center",
  },
  extLinkImgCred: {
    backgroundSize: "cover",
    height: 64,
    // width: 64,
    marginLeft: 8,
  },
  name: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "monospace",
    paddingTop: 10,
  },
  descr: {
    fontSize: 10,
    fontFamily: "monospace",
    paddingTop: 5,
    paddingBottom: 10,
  },
  line: {
    borderBottom: "1px solid grey",
  },
  date: {
    fontSize: 10,
    fontFamily: "monospace",
    paddingTop: 5,
  },
}


export default CertificationsCard